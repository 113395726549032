@font-face {
	font-family: SFProDisplaySemiBold;
	src: url("./Fonts/FontsFree-Net-SFProDisplay-Semibold.ttf");
}
@font-face {
	font-family: SfProDisplayMedium;
	src: url("./Fonts//FontsFree-Net-SFProDisplay-Medium\ \(2\).ttf");
}

:root {
	--heading: "SFProDisplaySemiBold";
	--body: "SfProDisplayMedium";
	--primary-color: 'white';
	--secondary-color: #ff3131
}

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

html {
	scroll-behavior: smooth;
	font-size: 100%;
}

body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
		"Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background-color: white;
	scroll-behavior: smooth;
	font-weight: 400;
	line-height: 1.75;
}

h1 {
	margin-top: 0;
	font-size: 4.209rem;
}

h2 {
	font-size: 3.157rem;
}

h3 {
	font-size: 2.369rem;
}

h4 {
	font-size: 1.777rem;
}

h5 {
	font-size: 1.333rem;
}

small,
.text_small {
	font-size: 0.75rem;
}

a {
	text-decoration: none;
	color: inherit;
}

.App {
	display: table-cell;
	vertical-align: middle;
}
/* Section Css */
/* section {
	height: 100vh;
	padding: 2rem 0;
} */

.App .work-wth-us-header {
	font-family: var(--heading);
	font-size: 2rem;
	line-height: 1.8rem;
	color: black;
	max-width: 75vw;
	min-height: 100vh;
	margin: 0 2rem;
	display: flex;
	flex-direction: column;
	justify-content: center;
}

/* global class:container : left aligned / white color*/
.container {
	text-align: left;
	color: black;
	max-width: 75vw;
	min-height: 100vh;
	margin: 0 2rem;
	margin-top: 1rem;
	z-index: 300;
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.container h2 {
	font-family: var(--heading);
	font-size: 2rem;
	line-height: 2rem;
	color: var(--secondary-color)
}

.container p {
	font-family: var(--body);
	font-size: 1rem;
	line-height: 1.75rem;
	margin-top: 1rem;
}

.container ul {
	margin: 2rem;
	font-family: var(--body);
	font-size: 1rem;
}
.container ul li {
	margin-bottom: 0.5rem;
	line-height: 1.2rem;
}

.container-small {
	padding-top: 45%;
}
/*nav*/

#nav {
	height: 100%;
	min-height: 100vh;
	width: 5rem;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	float: right;
	position: fixed;
	right: 0;
	text-align: center;
	z-index: 1;
	overflow: hidden;
}
.nav-container {
	list-style-type: none;
	margin: 0;
	padding: 0;
	display: flex;
	color: #f6f6f6;
	-webkit-transform: rotate(90deg);
	-moz-transform: rotate(90deg);
	-ms-transform: rotate(90deg);
	-o-transform: rotate(90deg);
	transform: rotate(90deg);
}
/*why the code is breaking upon adding class */
/*those are imp for nav animation true but each class has hight of 100vh so other componet is pusing down*/
/*used specoifity = ul li .home should ony style the list named home in ul*/
/*let us do the animation too , let me rename the section

first make a copy of this code somewhere then add animation
*/
.nav-item {
	position: relative;
	text-decoration: none;
	width: 8rem;
	height: 3rem;
	margin-bottom: 1.5rem;
	margin-right: 2rem;
	color: black
}
.nav-item a {
	position: absolute;
	width: 90%;
	left: 5%;
	top: 25%;
}
.active::before {
	transition: all 8s cubic-bezier(0.075, 0.82, 0.165, 1);
	content: "";
	background: url("./Images/HeaderPointer.svg");
	transform: rotate(-90deg);
	background-size: 100% 100%;
	height: 0.6rem;
	display: block;
}
.active {
	color: var(--secondary-color);
	/* transform: scale(1, 0.8); */
	transition: all 3s cubic-bezier(0.075, 0.82, 0.165, 1);
	margin-right: 67px;
	/* 67 px : 4.1875 rem  */
	/* 47 rem : 2.9375 rem */
}

/*Home section styles*/

.home {
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
}

.home img {
	display: flex;
	width: 50vw;
	height: 40vh;
}

/*About*/
.about {
	height: 100vh;
	padding: 2rem 0;
}

/* contact*/
.contact-container {
	margin: 0 2rem;
	padding-bottom: 2rem;
	color: black;
	width: 70vw;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	justify-content: center;
}
.contact-container h2 {
	font-family: var(--heading);
	font-size: 2rem;
	color: var(--secondary-color)
}

.address-container {
	height: 21rem;
	text-align: left;
	width: 100%;
}

.contact {
	height: 18rem;
	padding-bottom: 2rem;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	margin-top: 0.5rem;
}

.contact > div {
	margin: 0.3rem 0;
}
.contact > div > p:first-of-type {
	font-size: 0.8rem;
	color: black;
}
.contact p {
	font-family: var(--body);
	font-size: 1rem;
	font-weight: 900;
	color: black;
}

.contact h3 {
	font-family: var(--heading);
	font-size: 1.5rem;
	color: black;
	font-family: var(--heading);
}
.contact h3 span {
	font-family: var(--heading);
	font-size: 1rem;
}
.contact h3 > div {
	font-size: 0.8rem;
}

/*media queries*/
/*Tabs*/
@media screen and (min-width: 760px) and (min-width: 992px) {
	.container h2 {
		font-size: 4rem;
		line-height: 4rem;
		margin-bottom: 1rem;
		word-wrap: break-word;
		font-family: var(--heading);
	}
	.App .work-wth-us-header {
		font-size: 5rem;
		line-height: 5rem;
	}

	.container p {
		font-size: 1.5rem;
		line-height: 1.5rem;
		font-family: var(--body);
	}

	.container ul {
		font-size: 1.5rem;
	}
	.container ul li {
		line-height: 1.5rem;
	}

	.contact-container h2 {
		font-size: 4rem;
	}
.address-container {
		height: 25rem;
		text-align: left;
		width: 100%;
	}

	.contact h3 {
		font-size: 3rem;
	}
	.contact h3 span {
		font-size: 2rem;
	}

	.contact > div p:first-child {
		font-size: 1.2rem;
		letter-spacing: 0.2rem;
	}
	.contact > div p {
		font-size: 1.5rem;
	}

	#nav .nav-container {
		font-size: 1.3rem;
	}
}

/* Media Queries for desktop */